:root {
  --size-3: 3px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-14: 14px;
  --size-18: 18px;
  --size-16: 16px;
  --size-24: 24px;
  --size-30: 30px;
  --size-32: 32px;
  --size-36: 36px;
  --size-42: 42px;
  --size-48: 48px;
  --size-54: 54px;
  --size-60: 60px;
  --size-74: 74px;
  --size-96: 96px;
  --size-134: 134px;
  --size-150: 150px;

  --size-container-max-width-sm: 720px;
  --size-container-max-width-md: 864px;
  --size-container-max-width-lg: 1240px;

  --size-header-height-sm: 60px;
  --size-header-height-md: 75px;

  --scroll-offset: 100px;
}

:export {
  headerHeightSm: 60px;
  headerHeightMd: 75px;
}
